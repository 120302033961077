@import 'variables';

@mixin theming($name, $color) {
  .theme-#{$name} {
    .bs-datepicker-head {
      background-color: $color;
    }

    .bs-datepicker-body {
      table {
        td {
          span.selected,
          &.selected span,
          span[class*="select-"]:after,
          &[class*="select-"] span:after {
            background-color: $color;
          }

          &.week span {
            color: $color;
          }

          &.active-week span:hover {
            cursor: pointer;
            background-color: $color;
            color: $font-color-01;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}
