

body {
  padding-bottom: 50px;
}


.main-container {
  margin-top: 20px;
}

.img-logo {
  width: 120px;
}

.bg-dark{background-color: rgb(53, 136, 44,0.8)!important;}


/* Animaciones */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}
.ng-valid[required], .ng-valid.required {
  border: 1px solid #42A948; /* green */
}

.ng-invalid:not(form) {
  border: 1px solid #a94442; /* red */
}


/*=====================*/
/*FONT FACE*/
/* Body */
body {
  background-color: #eee;
}

/* Navbar */
button#sidebarCollapse:focus {
  outline: 0;
}

.top-navbar {
  background-color: #f3f3f3;
  height: 80px;
  padding: 16px;
}

  .top-navbar .logo img {
    max-width: 55px;
  }

.bottom-navbar {
  background-color: #4AB33E;
  height: 65px;
  padding: 7px 0;
}

.bottom-navbar .nav-link {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  margin-right: 19px;
  margin-left: 19px;
}

.badge-novedades {
  color: #fff;
  background-color: #e31566;
  position: absolute;
  top: 24px;
  left: 16px;
}

/* forms */
.form-control {
  border: 1px solid #fff;
  border-radius: 0;
  /*padding: 24px 18px;*/
}

.form-control-lg {
  height: 60px;
  border-radius: 0;
}

.form-control:focus {
  border: 1px solid #000;
  box-shadow: none;
}

textarea {
  resize: none;
  overflow-y: auto;
}

/* Checkbox */


/* buttons */
.btn {
  border-radius: 0;
}

.btn-primary {
  color: #fff;
  background: none;
  border-color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:focus {
  border: 1px solid #000;
  box-shadow: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.btn-secondary {
  color: #fff;
  background-color: #4AB33E;
  border-color: #4AB33E;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:focus {
  border: 1px solid #000;
  box-shadow: none;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-light {
  color: #4AB33E;
  background-color: #fff;
  border-color: #fff;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:focus {
  border: 1px solid #000;
  box-shadow: none;
}

.btn-light:hover {
  color: #fff;
  background-color: #4AB33E;
  border-color: #4AB33E;
}

.btn-light:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4AB33E;
  border-color: #4AB33E;
}

.btn-outline-primary {
  color: #000;
  background-color: transparent;
  border-color: #000;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4AB33E;
  border-color: #4AB33E;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4AB33E;
  border-color: #4AB33E;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  border: 1px solid #000;
  box-shadow: none;
}

  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-lg {
    border-radius: 0;
    height: 60px;
  }

/* Card */
.card {
  border-radius: 0;
  border: 1px solid #fff;
}

/* Modal */
.modal-content {
  border-radius: 0;
  border: 0;
}

.modal-body {
  padding: 1.6rem 1rem;
  color: #000;
  font-size: 1.3rem;
  line-height: 2;
  border-bottom: 1px solid #e0e0e0;
}


/* Varios */
.info {
  margin-top: 18px;
  font-size: 14px;
  color: #6d6d6d;
  line-height: 1.8;
  text-align: center !important;
}

.clickable {
  cursor: pointer;
}

/* Custom checkbox (Ver: W3School How TO - Custom Checkbox) */
.custom-checkbox label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  color: #fff;
}

  .custom-checkbox label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
}

.custom-checkbox label:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.5);
}

.custom-checkbox label input:checked ~ .checkmark {
  background-color: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox label input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox label .checkmark:after {
  left: 3px;
  top: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
}


/*Terminos y condiciones*/
.tyc {
  font-size: 1rem;
  text-align: left;
}


hr {
  margin: 8px 0 32px 0;
}
